<template>
  <b-container fluid>
    <InformacionGeneral />
    <InformacionContact />
    <CredencialesAportesEnLinea />
    <!-- <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">


        </b-col>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Información Contacto</b></h4>
            </template>

            <div class="row px-5 mt-3">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="nombre" class="form-label">Nombre Contacto</label>
                  <input v-model="item.nombreContacto" placeholder="Escriba el Nombre del Contacto..." type="text"
                    class="form-control" id="nombre" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="telefonoContacto" class="form-label">Número Contacto</label>
                  <input v-model="item.telefonoContacto" placeholder="Escriba el Número del Contacto..." type="text"
                    class="form-control" id="telefonoContacto" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Correo Electrónico</label>
                  <input v-model="item.correo" placeholder="Escriba el Correo Electronico..." type="email"
                    class="form-control" id="exampleFormControlInput1" />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Contraseña para IFrames</label>
                  <input v-model="item.contrasenaIFrame" placeholder="Escriba la Contraseña..." type="text"
                    class="form-control" id="exampleFormControlInput1" />
                </div>
              </div>
            </div>
            <div class="text-right d-flex justify-content-end px-5 pb-4">
              <vs-button
                 
                primary
                icon
                animation-type="vertical"
                type="submit"
                class="mr-1 "
              >
                <i class="fas fa-save pr-1" ></i> 
                Guardar Información
                <template #animate >
                  <i class="fas fa-save" ></i> 
                </template>
              </vs-button>
              <vs-button
                 
                danger
                icon
                animation-type="vertical"
                class="mx-1 "
                @click="$router.push('/helex/empresa')"
              >
                <i class="fas fa-xmark pr-1" ></i> 
                Cancelar
                <template #animate >
                  <i class="fas fa-xmark" ></i> 
                </template>
              </vs-button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form> -->
    <card>
      <template #headerTitle>
        <h4>IFrames de la empresa</h4>
      </template>
      <div class="container-fluid px-5">
        <BVForm :schema="iframePesvSchema" :callBackSubmit="saveIframe" title="Iframe PESV" :injectData="{seccion: 'PESV'}"></BVForm>
      </div>
      <div class="container-fluid px-5">
        <BVForm :schema="iframeSgsstSchema" :callBackSubmit="saveIframe" title="Iframe SGSST" :injectData="{seccion: 'SGSST'}"></BVForm>
      </div>
    </card>
  </b-container>
</template>
<script>

import BVForm from '@/components/BVForm/BVForm'
import { core } from "../../../config/pluginInit";
import Swal from "sweetalert2";
import InformacionGeneral from "./EditarEmpresa/InformacionGeneral.vue";
import InformacionContact from './EditarEmpresa/InformacionContact.vue';
import CredencialesAportesEnLinea from './EditarEmpresa/CredencialesAportesEnLinea.vue';


export default {
  name: "DataTable",
  components: {
    BVForm,
    InformacionGeneral,
    InformacionContact,
    CredencialesAportesEnLinea
  },
  data() {
    return {
      item: {},
      id: "",
      options: {},
      ListSector: [],
      iframePesvSchema: [],
      iframeSgsstSchema: []
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    core.index();
    window.$("#datatable_empresa").DataTable();
    await this.getData();
    await this.getListSector();
  },
  methods: {
    saveIframe(self) {
      let data = self.data
      data['empresaId'] = this.id

      self.loader = true
      this.$store.getters.fetchPost({ path: 'IFrame/CreateIframe', data })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error("No se ha guardado el iframe")
        })
        .then(() => {


          Swal.fire({
            title: 'Iframe guardado',
            text: 'Se ha guardado el iframe en la empresa',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          })


        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.message,
            icon: 'error',
            confirmButtonText: 'Aceptar'
          })
        })
        .finally(() => {
          self.loader = false
        })
    },
    actualizaSector(e) {
      console.log(e.target.value)
      this.item.sectorId = e.target.value
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        // let empresaZ = await this.$store.getters.fetchGet({ path: `Empresa/GetEmpresaById/${this.id}`})
        // console.log(await empresaZ.json() );

        // console.log("get empresas...", this.id);
        let res = await this.$store.dispatch("hl_get", {
          path: "Empresa/GetEmpresa/" + this.id,
        });
        // console.log("post empresas...", res);
        this.item = res;

        this.iframePesvSchema = JSON.parse(JSON.stringify(iframeSchema)),
        this.iframeSgsstSchema = JSON.parse(JSON.stringify(iframeSchema))

        let responseIframePesv = await this.$store.getters.fetchGet({ path: `IFrame/empresa/${this.$route.params.id}/seccion/PESV/last` })
        let iframePesv = await responseIframePesv.json()
        // console.log('iframePesv', iframePesv);
        this.iframePesvSchema.forEach(item => {
          item.value = iframePesv[item.name]
        })

        let responseIframeSgsst = await this.$store.getters.fetchGet({ path: `IFrame/empresa/${this.$route.params.id}/seccion/SGSST/last` })
        let iframeSgsst = await responseIframeSgsst.json()
        // console.log('iframeSgsst', iframeSgsst);
        this.iframeSgsstSchema.forEach(item => {
          item.value = iframeSgsst[item.name]
        })


      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
    async getListSector() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Sector/ListSector/",
        });
        this.ListSector = res;

        console.log('list', this.ListSector);
      } catch (error) {
        this.ListSector = {};
        console.log("err", error);
      }
    },
    async saveData() {
      console.log('saving', this.item);
      try {
        if (this.item.sectorId == '00000000-0000-0000-0000-000000000000')
          this.item.sectorId = this.item.sector.id
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/Update/", data: this.item
        });
        console.log("post empresas...", res);
        if (res)
          this.$router.push('/helex/empresa')
        else
          alert('Verificar')

      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
  },
};

const iframeSchema = [
  {
    validations: 'required',
    label: 'Url',
    name: 'url',
    type: 'text',
    value: null
  },
  {
    validations: '',
    label: 'Descripción',
    name: 'descripcion',
    type: 'text',
    value: null
  }
]
</script>
